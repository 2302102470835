<template>
  <b-tabs class="page-tabs" pills>
    <b-tab v-if="isActiveUser && isCompanyProfile">
      <template #title>
        <span>
          Badge & Feedback
        </span>
      </template>
      <div class="tab-pane__content">
        <loader-info v-if="isLoading" />
        <div v-if="!isLoading">
          <user-feedback
            v-if="hasScoreData && isActiveUser"
            :total-point="totalPoint"
            :count-total-wait="countTotalWait"
            :count-total-wait-max="countTotalWaitMax"
            :count-total="countTotal"
            :count-total-max="countTotalMax"
            :count-feedback-made-total="countFeedbackMadeTotal"
            :count-feedback-made-total-max="countFeedbackMadeTotalMax"
            :serial-code-creation="serialCodeCreation"
            :serial-code="serialCode"
          />
        </div>
      </div>
    </b-tab>
    <b-tab v-if="isActiveUser && isCompanyProfile">
      <template #title>
        <span>
          Statistiche Feedback
        </span>
      </template>
      <div class="tab-pane__content">
        <loader-info v-if="isLoading" />
        <div v-if="!isLoading">
          <score-feedback
            v-if="hasScoreData && isActiveUser"
            :serialCodeCreation="serialCodeCreation"
          />
        </div>
      </div>
    </b-tab>
    <b-tab>
      <template #title>
        <span>
          Lista Segnalazioni
        </span>
      </template>
      <div class="tab-pane__content">

        <b-card body-class="pt-1">
          <template #header>
            <card-header
              title="Segnalazioni Effettuate"
            />
          </template>

          <user-report-history-table
            :completeData="hasScoreData"
            searchNameExt="reportMadeTable"
          />
        </b-card>

        <b-card>
          <template #header>
            <card-header
              title="Segnalazioni Ricevute"
            />
          </template>

          <user-report-history-table
            :completeData="hasScoreData"
            searchNameExt="reportReceivedTable"
            reportMeExt
          />
        </b-card>

      </div>
    </b-tab>
    <b-tab>
      <template #title>
        <span>
          {{reportStatsLabel}}
        </span>
      </template>
      <div class="tab-pane__content">
        <b-row>
          <b-col md="6" class="d-flex mt-3 mt-md-0 flex-column" v-if="hasReportReceivedData">
            <b-card footer-class="p-0 overflow-hidden">
              <template #header>
                <card-header
                  title="Segnalazioni ricevute"
                />
              </template>
              <div class="text-center">
                <h1 :class="totalReceivedHighlightClasses">
                  {{ reportReceivedData.confirmedReport.countTotal }}
                </h1>
              </div>
              <template #footer>
                <div class="chart__progress-bars" v-if="hasReceivedData">
                  <div :style="receivedPositiveColWidth">
                    <b-progress
                      :value="reportReceivedData.confirmedReport.countPositive"
                      :max="reportReceivedData.confirmedReport.countPositive"
                      show-value
                      variant="success"
                      class="progress--is-squared"
                    />
                  </div>
                  <div :style="receivedNegativeColWidth">
                    <b-progress
                      :value="reportReceivedData.confirmedReport.countNegative"
                      :max="reportReceivedData.confirmedReport.countNegative"
                      show-value
                      variant="danger"
                      class="progress--is-squared"
                    />
                  </div>
                </div>
                <div class="chart-unavailable__count" v-if="!hasReceivedData">
                  <span>N/A</span>
                </div>
              </template>
            </b-card>
          </b-col>
          <b-col md="6" class="d-flex mt-3 mt-md-0 flex-column" v-if="hasReportSendData">
            <b-card footer-class="p-0 overflow-hidden">
              <template #header>
                <card-header
                  title="Segnalazioni effettuate"
                />
              </template>
              <div class="text-center">
                <h1 :class="totalSendHighlightClasses">
                  {{ reportSendData.confirmedReport.countTotal }}
                </h1>
              </div>
              <template #footer>
                <div class="chart__progress-bars" v-if="hasSendData">
                  <div :style="sendPositiveColWidth">
                    <b-progress
                      :value="reportSendData.confirmedReport.countPositive"
                      :max="reportSendData.confirmedReport.countPositive"
                      show-value
                      variant="success"
                      class="progress--is-squared"
                    />
                  </div>
                  <div :style="sendNegativeColWidth">
                    <b-progress
                      :value="reportSendData.confirmedReport.countNegative"
                      :max="reportSendData.confirmedReport.countNegative"
                      show-value
                      variant="danger"
                      class="progress--is-squared"
                    />
                  </div>
                </div>
                <div class="chart-unavailable__count" v-if="!hasSendData">
                  <span>N/A</span>
                </div>
              </template>
            </b-card>
          </b-col>
        </b-row>
        <b-card class="mt-3" body-class="pb-0">
          <template #header>
            <card-header
              title="Segnalazioni Inviate"
            />
          </template>

          <b-row v-if="hasScoreData">
            <report-data-section
              v-for="report in userSendSections"
              :key="report.key"
              :reportData="report"
            />
          </b-row>
        </b-card>
        <b-card body-class="pb-0">
          <template #header>
            <card-header
              title="Segnalazioni Ricevute"
            />
          </template>

          <b-row v-if="hasScoreData">
            <report-data-section
              v-for="report in userReceivedSections"
              :key="report.key"
              :reportData="report"
            />
          </b-row>
        </b-card>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

const UserReportHistoryTable = () => import('@/components/userReports/UserReportHistoryTable.vue');
const ReportDataSection = () => import('@/components/userSections/ReportDataSection.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');
const ScoreFeedback = () => import('@/components/userSections/ScoreFeedback.vue');
const UserFeedback = () => import('@/components/userSections/UserFeedback.vue');
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');

export default {
  name: 'TabReportHistory',
  props: {
    hasScoreData: Boolean,
  },
  components: {
    UserReportHistoryTable,
    ReportDataSection,
    CardHeader,
    ScoreFeedback,
    UserFeedback,
    LoaderInfo,
  },
  data() {
    return {
      reportReceivedGlobal: false,
    };
  },
  computed: {
    isActiveUser() {
      return this.$store.getters['subject/isActiveUser'];
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    isLoading() {
      if (!this.isCompanyProfile) {
        this.changePreloaderStatus(this.$store.getters['subject/isLoading'] || this.$store.getters['score/isLoading']);
      }
      return this.$store.getters['subject/isLoading'] || this.$store.getters['score/isLoading'];
    },
    totalPoint() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.totalPoint;
      }
      return 0;
    },
    countTotalWait() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.feedbackWait;
      }
      return 0;
    },
    countTotalWaitMax() {
      let increm = this.countTotalWait * 0.2;
      if (increm < 1) {
        increm = 2;
      }
      return this.countTotalWait + increm;
    },
    countTotal() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.globalVotes?.countTotal;
      }
      return 0;
    },
    countTotalMax() {
      let increm = this.countTotal * 0.2;
      if (increm < 1) {
        increm = 2;
      }
      return this.countTotal + increm;
    },
    countFeedbackMadeTotal() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.feedbackMade;
      }
      return 0;
    },
    countFeedbackMadeTotalMax() {
      let increm = this.countFeedbackMadeTotal * 0.2;
      if (increm < 1) {
        increm = 2;
      }
      return this.countFeedbackMadeTotal + increm;
    },
    serialCode() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.serialCode;
      }
      return '';
    },
    serialCodeCreation() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.serialCodeCreation;
      }
      return '';
    },
    reportReceivedData() {
      const score = this.$store.getters['score/getCurrentReportReceived'] || {};
      return score;
    },
    hasReportReceivedData() {
      return isNotEmpty(this.$store.getters['score/getCurrentReportReceived']);
    },
    reportSendData() {
      const score = this.$store.getters['score/getCurrentReportSend'] || {};
      return score;
    },
    hasReportSendData() {
      return isNotEmpty(this.$store.getters['score/getCurrentReportSend']);
    },
    receivedPositiveColWidth() {
      const a = this.reportReceivedData.confirmedReport.countPositive;
      return `width: ${(a / this.reportReceivedData.confirmedReport.countTotal) * 100}%`;
    },
    receivedNegativeColWidth() {
      const a = this.reportReceivedData.confirmedReport.countNegative;
      return `width: ${(a / this.reportReceivedData.confirmedReport.countTotal) * 100}%`;
    },
    hasReceivedData() {
      return !(this.reportReceivedData.confirmedReport.countTotal <= 0);
    },
    sendPositiveColWidth() {
      return `width: ${(this.reportSendData.confirmedReport.countPositive / this.reportSendData.confirmedReport.countTotal) * 100}%`;
    },
    sendNegativeColWidth() {
      return `width: ${(this.reportSendData.confirmedReport.countNegative / this.reportSendData.confirmedReport.countTotal) * 100}%`;
    },
    hasSendData() {
      return !(this.reportSendData.confirmedReport.countTotal <= 0);
    },
    totalReceivedHighlightClasses() {
      if (this.reportReceivedData.globalVotes.countTotal > 99 && this.reportReceivedData.globalVotes.countTotal < 999) {
        return 'm-auto point-highlight point-highlight--100';
      }
      if (this.reportReceivedData.globalVotes.countTotal > 999) {
        return 'm-auto point-highlight point-highlight--1000';
      }
      return 'm-auto point-highlight';
    },
    totalSendHighlightClasses() {
      if (this.reportSendData.globalVotes.countTotal > 99 && this.reportSendData.globalVotes.countTotal < 999) {
        return 'm-auto point-highlight point-highlight--100';
      }
      if (this.reportSendData.globalVotes.countTotal > 999) {
        return 'm-auto point-highlight point-highlight--1000';
      }
      return 'm-auto point-highlight';
    },
    userSendSections() {
      const feedbackTypes = this.$store.getters['tableUtils/getUserReportType'];
      return feedbackTypes.map(this.prepareDataSend);
    },
    userReceivedSections() {
      const feedbackTypes = this.$store.getters['tableUtils/getUserReportType'];
      return feedbackTypes.map(this.prepareDataReceived);
    },
    reportStatsLabel() {
      if (this.isCompanyProfile) return 'Statistiche Segnalazioni';
      return 'Statistiche';
    },
  },
  methods: {
    prepareDataSend(type) {
      // const score = this.$store.getters['score/getCurrentScore'] || {};
      const hasScore = this.hasScoreData;
      if (hasScore) {
        const data = this.reportSendData.sectionTotals[type.key];
        if (isNotEmpty(data)) {
          return {
            ...type,
            hasValues: true,
            dataValues: { ...data },
          };
        }
      }
      return {
        ...type,
        hasValues: false,
      };
    },
    prepareDataReceived(type) {
      // const score = this.$store.getters['score/getCurrentScore'] || {};
      const hasScore = this.hasScoreData;
      if (hasScore) {
        const data = this.reportReceivedData.sectionTotals[type.key];
        if (isNotEmpty(data)) {
          return {
            ...type,
            hasValues: true,
            dataValues: { ...data },
          };
        }
      }
      return {
        ...type,
        hasValues: false,
      };
    },
  },
};
</script>

<style scoped>

</style>
